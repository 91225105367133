@font-face {
  font-family: "beautiful";
  src: url("https://storage.googleapis.com/note15-assets-ja/fonts/beautiful-sub.woff2"),
    url(/fonts/beautiful.ttf);
}

@font-face {
  font-family: "note15serif";
  src: local(Georgia), local(游明朝), local("Yu Mincho"), local(YuMincho),
    local("Hiragino Mincho ProN"), local(HGS明朝E), local(メイリオ),
    local(Meiryo), local("Roboto Serif");
}

html {
  background-color: #000;
}

body {
  margin: 0;
  padding: 0;
  font-family: "note15serif", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;

  background: url("/images/background.jpg") no-repeat;
  background-size: 100% auto;
  background-position: top center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 767px) {
  body::before {
    background: url("/images/background-small.jpg") no-repeat !important;
    background-size: 100% auto !important;
    background-position: top center !important;
  }
}
